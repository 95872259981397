<template>
  <v-container>
    <v-card>
      <v-tabs v-if="!loadingRooms" v-model="tab" show-arrows>
        <v-btn text large color="primary" class="pb-2" @click="showCreateTableDialog = true">
          <v-icon>add</v-icon>
          {{ $t('settings.lang_addTable') }}
        </v-btn>
        <template v-if="!loadingRooms">
          <v-tab ripple v-for="(room,index) in rooms" :key="`room-${index}`">
            <span>{{ room[1] }}</span>
          </v-tab>
        </template>
        <v-tab ripple>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">list</v-icon>
            </template>
            <span>{{ $t('generic.lang_tableView') }}</span>
          </v-tooltip>
        </v-tab>
        <v-tab ripple>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">dashboard</v-icon>
            </template>
            <span>{{ $t('generic.lang_cardView') }}</span>
          </v-tooltip>
        </v-tab>

        <v-tab ripple>
          <span>{{ $t('settings.lang_changeTableOwner') }}</span>
        </v-tab>
        <!--        <v-tab ripple>
                  <span @click="$router.push({name:'pos.gastro'})">{{ $t('generic.lang_table_Tresenverkau') }}</span>
                </v-tab>-->

        <v-btn text large color="muted" class="pb-2" @click="$router.replace({name:'pos.gastro.tresenverkauf'})">
          <span class="text-muted">{{ $t('generic.lang_table_Tresenverkau') }}</span>
        </v-btn>

        <template v-if="!loadingRooms">
          <v-tab-item v-for="(room,index) in rooms" :key="`room-${index}`">
            <room-floor-component :room-tab="index" :roomId="room[0]" :saved-tables="tables" :statuses="tablesStatuses"
                                  @revealResInfo="revealResInfo"
                                  @getTables="getTables()"/>
          </v-tab-item>
        </template>

        <v-tab-item>
          <v-container style="margin-top: 20px;">
            <v-layout>
              <v-flex md12>
                <v-card>
                  <v-toolbar color="transparent" flat>
                    <v-btn ripple @click="showCreateTableDialog = true" text color="primary">
                      <v-icon>add</v-icon>
                      {{ $t('settings.lang_addTable') }}
                    </v-btn>
                    <v-spacer/>
                    <v-btn :disabled="loading" style="float: right;" :loading="loading" icon text @click="getTables">
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <br>

                  <v-data-table
                      :headers="datatableHeaders"
                      :items="tables"
                      :loading="loading"
                      class="elevation-1"
                      :no-data-text="$t('generic.lang_noEntriesFound')"
                  >
                    <template v-slot:item.total="{ item }">
                      {{item.total | currency}}
                    </template>
                    <template v-slot:item.crud="{ item }">
                      <v-row class="min-width"
                             align="center"
                             justify="center">
                        <v-icon v-if="lockedTables.includes(item.name)" color="error" size="20">mdi-lock</v-icon>
                        <v-btn v-else
                            :disabled="!$store.getters['permissions/checkPermission']('allowAccessAllTables') && item.soldBy !== null && item.soldBy !== user.userID"
                            @click="selectTable(item)" class="mx-auto"
                            color="primary" icon text>
                          <v-icon>remove_red_eye</v-icon>
                        </v-btn>
                      </v-row>
                    </template>

                    <template v-if="!loading" v-slot:no-data>
                      <p class="text-xs-center" style="margin-top: 10px;">{{ $t('settings.lang_noOccupiedTables') }}</p>
                    </template>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container style="margin-top: 20px;">
            <v-layout>
              <v-flex md12>
                <v-btn ripple @click="showCreateTableDialog = true" text color="primary">
                  <v-icon>add</v-icon>
                  {{ $t('settings.lang_addTable') }}
                </v-btn>
                <v-btn :disabled="loading" :loading="loading" icon text @click="getTables">
                  <v-icon>refresh</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <br>

            <v-layout row wrap>
              <v-flex md3 v-for="table in tables" :key="table.name">
                <v-card
                    :color="(!$store.getters['permissions/checkPermission']('allowAccessAllTables') && table.soldBy !== null && table.soldBy !== user.userID)? 'grey' : ''"
                    :disabled="(!$store.getters['permissions/checkPermission']('allowAccessAllTables') && table.soldBy !== null && table.soldBy !== user.userID)||lockedTables.includes(table.name)"
                    style="margin: 30px;" ripple
                    @click="selectTable(table)"
                    :dark="!(!$store.getters['permissions/checkPermission']('allowAccessAllTables') && table.soldBy !== null && table.soldBy !== user.userID)">
                  <v-card-text>
                    <p style="text-align: right;">{{ table.date }}</p>
                    <p style="text-align: center; font-weight: bold; font-size: 24px; margin-top: 10px;">{{
                        table.name
                      }}</p>
                    <p>{{ table.totalParties }} {{ (table.totalParties > 1 ? "Parteien" : "Partei") }}</p>
                    <v-icon v-if="lockedTables.includes(table.name)" style="position: absolute;bottom: 20px;right: 20px"  color="white" size="20">mdi-lock</v-icon>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container style="margin-top: 20px;">
            <v-layout>
              <v-flex md4 pa-2 pb-0>
                <v-select :items="waiters"
                          item-text="preName"
                          item-value="userID"
                          :label="$t('settings.lang_selectWaiter')"
                          v-model="userID"
                          outlined
                >
                </v-select>
              </v-flex>
              <v-flex md4 pa-1 pb-0>
                <v-btn color="info" x-large
                       v-if="this.$store.getters['permissions/checkPermission']('changeTableOwner')"
                       :disabled="selected.length===0||userID===null"
                       @click="changeTableOwner"
                >
                  {{ $t('generic.lang_apply') }}
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md12>
                <v-card>
                  <v-data-table
                      :headers="tableOwnerDatatableHeaders"
                      :items="tables"
                      :loading="loadingOwners"
                      class="elevation-1"
                      show-select
                      item-key="name"
                      selectable-key="name"
                      :no-data-text="$t('generic.lang_noEntriesFound')"
                      v-model="selected"
                  >
                    <template v-slot:item.timestamp="{item}">
                      {{ convertTime(item.timestamp) }}
                    </template>
                    <template v-slot:item.soldBy="{item}">
                      {{ getWaitersName(item.soldBy) }}
                    </template>
                    <template v-slot:item.total="{item}">
                      {{ item.total | currency }}
                    </template>
                    <template v-if="!loading" v-slot:no-data>
                      <p class="text-xs-center" style="margin-top: 10px;">{{ $t('settings.lang_noOccupiedTables') }}</p>
                    </template>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

      </v-tabs>

      <v-dialog v-model="showCreateTableDialog" width="500">
        <v-card>
          <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : 'grey lighten-2']" class="headline" primary-title>{{ $t('settings.lang_addTable') }}</v-card-title>

          <v-form v-model="validTableName" lazy-validation>
            <v-card-text>
              <v-layout wrap>
                <v-flex md12>
                  <v-text-field type="text" v-model="tableName" :rules="[rules.checkZero,rules.required]" @keypress="preventSpecialChars" :label="$t('generic.lang_table')"/>
                </v-flex>

                <v-flex md12>
                  <v-text-field type="text" v-model="partyName" @keypress="preventSpecialChars" :label="$t('generic.lang_party')"/>
                </v-flex>

                <v-flex md12>
                  <Numpad disableComma @clickButton="clickNumpad" @resetField="tableName = ''"/>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-form>

          <v-divider/>

          <v-card-actions>
            <v-btn @click="showCreateTableDialog = false" text color="error">{{ $t('generic.lang_cancel') }}</v-btn>
            <v-spacer/>
            <v-btn color="success" @click="createTable" :disabled="!validTableName || this.lockTableName == this.tableName">{{ $t('generic.lang_create') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-overlay v-model="loadingTableSelect" >
      <v-progress-circular class="mx-auto" indeterminate size="150" color="primary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<style scoped>
.container {
  padding: 0 !important;
}
</style>

<script>
import {ENDPOINTS} from "../../config";
import {Events} from "../../plugins/events";
import Numpad from "./Numpad";
import RoomFloorComponent from "./RoomFloorComponent";
import moment from "moment"
import {mapGetters, mapState} from "vuex";
import randomString from "randomstring";
import validation from "../../mixins/validation/validation";

export default {
  name: "Tables",

  components: {
    RoomFloorComponent,
    Numpad
  },
  mixins:[validation],
  data() {
    return {
      ENDPOINTS,
      // --- Datatable ---
      datatableHeaders: [
        {text: this.$t('generic.lang_name'), value: 'name'},
        {text: this.$t('generic.lang_numberOfParties'), value: 'totalParties'},
        {text: this.$t('generic.lang_total'), value: 'total'},
        {text: this.$t('generic.lang_lastchange'), value: 'date'},
        {text: '', value: 'crud', sortable: false, width: '230', align: 'end'}
      ],
      tableOwnerDatatableHeaders: [
        {text: this.$t('generic.lang_name'), value: 'name'},
        {text: this.$t('generic.lang_numberOfParties'), value: 'totalParties'},
        {text: this.$t('generic.lang_lastchange'), value: 'timestamp'},
        {text: this.$t('generic.lang_waiter'), value: 'soldBy'},
        {text: this.$t('generic.lang_total'), value: 'total'},
      ],
      tab: null,
      tables: [],
      rooms: [],
      room: {},
      loading: true,
      loadingRooms: true,
      loadingOwners: false,
      posType: "gastro",
      showCreateTableDialog: false,
      loadingTableSelect: false,
      requestError: false,
      tableName: "",
      partyName: "Partei 1",
      waiters: [],
      userID: null,
      selected: [],
      tablesStatuses: [],
      showResInfo: true,
      validTableName: true,
      lockTableName: null,
    }
  },

  mounted() {
    this.getTables();
    this.getAllRooms().then(() => {
      this.$nextTick(() => {
        this.tab = this.pos.gastro.lastRoom.index;
      })
    })
    this.getWaiters();
    this.getTablesStatuses();
    //this.getOrder('no table')
  },
  computed: {
    ...mapState([
      'pos',
      'user'
    ]),
    ...mapGetters({
      lockedTables:'pos/gastro/lockedTables',
    }),
    filteredTableOwnerHeaders() {
      return null;
    },
  },
  watch: {
    showCreateTableDialog() {
      this.tableName = "";
      this.lockTableName = null;
    },
    tab(val) {
      this.showResInfo = !this.showResInfo;
    },
  },
  methods: {
    revealResInfo() {
      this.showResInfo = true;
    },
    getTablesStatuses() {
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.STATUSES.GET, {}).then(res => {
        if (res.data.STATUS === "SUCCESS") {
          this.tablesStatuses = res.data.statuses;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || res.data.STATUS,
            color: "error"
          })
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        })
      })
    },
    clickNumpad(value) {
      this.tableName += value;
    },
    getTables() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {
        if (res.status === 200) {
          this.tables = res.data.tables;
          this.loading = false;
        }
      }).catch((err) => {
        this.loading = false;
      });
    },
    async selectTable(table) {
      // CHECK IF THE SELECTED TABLE IS NOT ALREADY IN USE
      if(this.lockedTables.includes(table.name)){
        return;
      }
      //CHECK IF WE ALREADY ACCESSING A TABLE
      if(this.loadingTableSelect)
        return;

      this.loadingTableSelect=true;
      let tmpTable=Object.assign({},table);

      await this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETROOMBYTABLE,{
        tableName:table.name,
      }).then(res=>{
        if(res.data.status){
          tmpTable.roomName = res.data.roomName;
        }
      }).catch(err=>{
        this.loadingTableSelect=false;
        this.requestError=true;
        this.$swal({
          title: this.$t('generic.lang_errorOccurred'),
          text: "",
          icon: "error",
          confirmButtonText:this.$t('generic.lang_refreshPage'),
          showConfirmButton:true,
          showCancelButton: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.$router.go();
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      })

      if(this.requestError)
        return;

      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: tmpTable
      }).then(() => {
        this.$socket.emit("tableplan.setTable", table.name);
        this.$router.replace("/pos/gastro");
      }).finally(()=>{
        this.loadingTableSelect=false;
      })
    },
    async createTable() {
      let date = new Date();
      let time = date.getHours() + ":" + date.getMinutes();
      this.loading = false;
      let table = {
        name: this.tableName,
        parties: [
          {
            name: this.partyName
          }
        ],
        totalParties: 1,
        total: 0,
        date: time,
      };

      let tmpTable = this.tables.find(searchTable => table.name === searchTable.name)

      if (tmpTable) {
        table = Object.assign({}, tmpTable);
      }

      // CHECK IF TABLE IS LOCKED
      if (!this.$store.getters['permissions/checkPermission']('allowAccessAllTables')) {
        if (this.lockTable(table.name)) {
          this.lockTableName = this.tableName;
          
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_tableInUseByAnotherWaiter'),
            color: "error"
          });

          return;
        }
      }

      if(this.loadingTableSelect)
        return;

      this.loadingTableSelect=true;
      await this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETROOMBYTABLE,{
        tableName:table.name,
      }).then(res=>{
        if(res.data.status){
          table.roomName = res.data.roomName;
        }
      })

      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: table,
      }).then(() => {
        this.$router.replace("/pos/gastro");
      }).finally(()=>{
        this.loadingTableSelect=false;
      })
    },
    lockTable(name) {
      // CHECK WEBSOCKET LOCK
      if (this.lockedTables.findIndex((tableName) => tableName === name) > -1)
        return true;

      // CHECK SAVED TABLE
      if (this.findTable(name) !== -1) {
        let table = this.tables[this.findTable(name)];

        if (parseInt(table.soldBy) > 0)
          if (table.soldBy !== this.user.userID) return true;
      }

      return false;
    },
    findTable(name) {
      if (this.tables.length > 0) {
        for (let i = 0; i < this.tables.length; i++) {
          if (name === this.tables[i].name) {
            return i;
          }
        }
      }
      return -1;
    },
    async getAllRooms() {
      this.loadingRooms = true;
      this.axios.post("get/settings/allRooms/")
          .then((res) => {
            if (res.status === 200) {
              this.rooms = res.data.roomInformations;
              this.loadingRooms = false;
            }
          }).catch((err) => {
        this.loadingRooms = false;
      });
    },
    getWaiters() {
      this.loadingOwners = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.WAITERS.GET)
          .then((res) => {
            if (res.status === 200) {
              this.waiters = res.data.users;
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        })
      }).finally(fin => {
        this.loadingOwners = false;
      });
    },
    getWaitersName(id) {
      let user = this.waiters.find(klerk => {
        return klerk.userID === id
      });
      if (user !== null && user !== undefined) {
        return user.preName + ' ' + user.lastName;
      } else {
        return this.$t('generic.lang_notFound');
      }
    },
    convertTime(time) {
      return moment.unix(time).format('DD.MM.YYYY HH:mm');
    },
    changeTableOwner() {

      if (this.selected.length === 0 || this.userID === null) {
        return;
      }
      let currentTab = this.tab
      this.loadingOwners = true;

      let tableIDs = [];

      for (let i = 0; i < this.selected.length; i++) {
        tableIDs.push(this.selected[i].name);
      }

      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.WAITERS.UPDATE, {
        tableIDs: tableIDs,
        userID: this.userID,
      }).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.selected = [];
          this.getTables();
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loadingOwners = false;
        this.tab = currentTab;
      });
    },
  }
}
</script>
